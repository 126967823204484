<template>
  <div>
    <div class="total">
      <el-row :gutter="20">
        <el-col :span="6">
          <div class="price">+4354545.00</div>
          <div class="title">收入到账合计</div>
        </el-col>
        <el-col :span="6">
          <div class="price">+4354545.00</div>
          <div class="title">收入未分账合计</div>
        </el-col>
        <el-col :span="6">
          <div class="price">+4354545.00</div>
          <div class="title">支出未分账合计</div>
        </el-col>
        <el-col :span="6">
          <div class="price">2</div>
          <div class="title">异常账单</div>
        </el-col>
      </el-row>
    </div>

    <div class="merchantCenterList MERCH">
      <!-- 供应商中心 -->
      <div class="merch-card">
        <!-- 搜索列 -->
        <div class="merch-search">
          <el-form ref="searchParams" :model="searchParams" :inline="true">
            <div style="display: flex;">
              <el-form-item prop="complainant" style="flex:1">
                <el-input autocomplete="off" maxlength="32" v-model="searchParams.complainant" placeholder="请选择账单时间"
                  clearable @keyup.enter.native="handleCurrentChange" />
              </el-form-item>
              <el-form-item prop="supplier" style="flex:1">
                <el-input maxlength="32" v-model="searchParams.supplier" placeholder="请输入账单编号" clearable
                  @keyup.enter.native="handleCurrentChange" />
              </el-form-item>
              <el-form-item prop="complaint" style="flex:1">
                <el-input maxlength="32" v-model="searchParams.complaint" placeholder="请输入平台订单号" clearable
                  @keyup.enter.native="handleCurrentChange" />
              </el-form-item>
              <el-form-item prop="complaint" style="flex:1">
                <el-input maxlength="32" v-model="searchParams.complaint" placeholder="请输入支付流水号" clearable
                  @keyup.enter.native="handleCurrentChange" />
              </el-form-item>
            </div>
            <div style="display: flex;">
              <el-form-item prop="complainant" style="flex:1">
                <el-input autocomplete="off" maxlength="32" v-model="searchParams.complainant" placeholder="请输入账单单位"
                  clearable @keyup.enter.native="handleCurrentChange" />
              </el-form-item>
              <el-form-item prop="supplier" style="flex:1">
                <el-select style="width: 100%;" v-model="BillingResults" clearable placeholder="请选择支付对账结果">
                  <el-option v-for="item in options" :key="item.value" :label="item.name" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="complaint" style="flex:1">
                <el-select style="width: 100%;" v-model="BillingResults" clearable placeholder="请选择分账状态">
                  <el-option v-for="item in options" :key="item.value" :label="item.name" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="complaint" style="flex:1">
                <el-button type="primary" @click="handleCurrentChange">查 询</el-button>
                <el-button @click="handleCurrentChange">重 置</el-button>
                <el-button type="primary" @click="handleCurrentChange">导 出</el-button>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <!-- 列表 -->
        <div class="merch-table scorr-roll" :style="`max-height: ` + mainHright + `px`">
          <div>
            <el-table :data="searchListData" border style="width: 100%">
              <el-table-column prop="id" label="序号" align="center" width="60"></el-table-column>
              <el-table-column prop="complainant" label="账单编号" align="center"></el-table-column>
              <el-table-column prop="telephone" label="平台订单号" align="center"></el-table-column>
              <el-table-column prop="createTime" label="支付流水号" align="center"></el-table-column>
              <el-table-column prop="supplier" label="订单类型" align="center"></el-table-column>
              <el-table-column prop="complaint" label="支付渠道" align="center"></el-table-column>
              <el-table-column prop="mobile" label="平台账单金额" align="center"></el-table-column>
              <el-table-column prop="complaintContent" label="支付平台金额" align="center" min-width="110">
                <template slot-scope="scope">
                  <el-tooltip class="item" effect="dark" :content="scope.row.complaintContent" placement="bottom"
                    :disabled="scope.row.complaintContent.length > 30 ? false : true">
                    <div>{{ scope.row.complaintContent.length > 30 ? scope.row.complaintContent.slice(0, 30) + '...' :
                      scope.row.complaintContent }}</div>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column prop="complaint" label="手续费" align="center"></el-table-column>
              <el-table-column prop="mobile" label="手续费比例" align="center"></el-table-column>
              <el-table-column prop="complaint" label="支付差异数" align="center"></el-table-column>
              <el-table-column prop="mobile" label="支付对账结果" align="center"></el-table-column>
              <el-table-column prop="complaint" label="结算状态" align="center"></el-table-column>
              <el-table-column prop="mobile" label="账单单位" align="center"></el-table-column>
              <el-table-column prop="complaint" label="账单时间" align="center"></el-table-column>
              <el-table-column label="操作" width="100px" align="center">
                <template slot-scope="scope">
                  <div class="details">详情</div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- 分页 -->
        <div class="merch-page">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="currentPage4" :page-sizes="[100, 200, 300, 400]" :page-size="100"
            layout="total, sizes, prev, pager, next, jumper" :total="400">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const merchantCenterList = createNamespacedHelpers("merchantCenterList");
export default {
  data() {
    return {
      BillingResults: '',
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],


      total: 0,
      current_page: null,
      searchParams: {
        page: 1,
        perPage: 10,
        complainant: '',
        supplier: '',
        complaint: '',
      },
      searchListData: []
    }
  },
  computed: {
    ...commonIndex.mapState(["mainHright",]),
    ...merchantCenterList.mapState(["page"]),
  },
  created() {
    this.getSearchList()
  },
  methods: {
    ...merchantCenterList.mapActions([
      "getComplainsRecords",
    ]),
    async getSearchList() {
      let { data } = await this.getComplainsRecords(this.searchParams)
      this.searchListData = data.data
      this.current_page = data.currentPage
      this.total = data.total
    },
    //当前页
    searchBusinessList(e) {
      if (e != undefined) {
        this.searchParams.page = e;
      }
      this.getSearchList()
    },
    //搜索
    handleCurrentChange() {
      this.searchParams.page = 1;
      this.getSearchList()
    },
  }
};
</script>
<style lang="scss" scoped>
::v-deep .el-form {
  display: flex;
  flex-direction: column;
}

::v-deep .el-form-item__content {
  width: 90%;
}

::v-deep .el-form--inline .el-form-item {
  text-align: center;
}

::v-deep .el-tabs__item.is-active {
  color: #06b7ae;
}

::v-deep .el-tabs__item:hover {
  color: #06b7ae;
}

::v-deep .details {
  color: #06b7ae;
  padding: 10px;
}

::v-deep .el-tabs__active-bar {
  background-color: #06b7ae;
}

::v-deep .el-input-group__prepend {
  padding: 0px 0px 0px 5px !important;
}

::v-deep .el-input__inner {
  border-radius: 4px !important;
}

::v-deep .el-range-separator {
  width: 20% !important;
}
</style>
<style lang="scss" scoped>
.MERCH {
  width: 100%;
  height: 100%;

  .merch-card {
    width: 100%;
    max-height: 100%;
    padding: 20px;
    padding-top: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;

    .merch-tag {
      width: 100%;
      height: 40px;
    }

    // 搜索行样式
    .merch-search {
      margin-top: 1%;
      width: 100%;

      .search-box {
        display: flex;
        flex-wrap: wrap;
      }

      .sch-1 {
        margin-top: 10px;
        width: 18%;
        min-width: 200px;
        display: flex;

        .sch-title {
          width: 40%;
          min-width: 80px;
          height: 100%;
          font-size: 13px;
          background-color: #f5f7fa;
          color: #909399;
          border: 1px solid #dcdfe6;
          border-right: none;
          border-radius: 4px 0px 0px 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px 0px 0px 5px !important;
        }
      }

      .sch-2 {
        margin-right: 2%;
      }
    }

    .merch-table {
      //   margin-top: 1%;
      width: 100%;
      height: 77%;
    }

    .merch-page {
      margin-top: 10px;
      width: 100%;
      display: flex;
      align-content: center;
      justify-content: right;
    }
  }
}

.el-select {
  width: 325px;
}

.el-button--default {
  background-color: rgba($color: #2E3238, $alpha: 0.05);
  color: #383B46;
  border: none;
}

.total {
  margin: 10px;

  .el-row {
    background-color: #FFFFFF;
    padding: 30px 0;
    border-radius: 4px;
    font-weight: bold;
  }

  .el-col {
    text-align: center;

    .title {
      margin-top: 20px;
    }
  }
}
</style>
  