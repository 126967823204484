import { render, staticRenderFns } from "./billingOrder.vue?vue&type=template&id=c2ffa754&scoped=true&"
import script from "./billingOrder.vue?vue&type=script&lang=js&"
export * from "./billingOrder.vue?vue&type=script&lang=js&"
import style0 from "./billingOrder.vue?vue&type=style&index=0&id=c2ffa754&lang=scss&scoped=true&"
import style1 from "./billingOrder.vue?vue&type=style&index=1&id=c2ffa754&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2ffa754",
  null
  
)

export default component.exports